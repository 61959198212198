import React, {useState} from "react";
import {columnsAllCra} from "./data/columnsAll";
import {
    AdaFilterSubHeader,
    AdaTable,
    AdaActions,
    AdaButton
} from "@adaming/ada-react-component";
import IntlMessages from "../../helpers/intlMessages";

import {useHistory, withRouter} from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import * as service from "../../service/crud.service";
import {BILLING_NAME, CRA_NAME, getHostByAppName} from "../../service/host";
import axios from "axios";
import DetailsTimeSheet from "./details/details.timesheet";
import AdvancedSearchRH from "./search/advanced.search.rh";
import {GetOrganizationIdFromSession} from "../../service/session.service";
import {Col, Row} from "reactstrap";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import { getConfigObject} from "@adaming/ada-react-component";


const context = "timesheets";

const AllTimesheets = props => {
    const [cras, setCras] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(true);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);

    const [currentFilters, setCurrentFilters] = useState({});

    let history = useHistory();

    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.consulter`}/>,
                href: `/allTimesheets/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`cra.validate`}/>,
                href: "",
                actionHandler: handlerValidateAction,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`cra.refuse`}/>,
                href: "",
                actionHandler: handlerRefuseAction,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`cra.generate.invoices`}/>,
                href: "",
                actionHandler: handlerGenerateInvoicesShow,
                disabled: true
            }
        ];
    };


    const handlerValidateAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerCraValidateOrRefuseActionRh(
            selectedRows.map(element => element.id),
            true
        );
    };

    const handlerRefuseAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerCraValidateOrRefuseActionRh(
            selectedRows.map(element => element.id),
            false
        );
    };

    const handlerGenerateInvoicesShow = ()=>{
        setModalShow(true)
    }

    const handlerGenerateInvoicesAction = () => {
            console.log("in generate invoices action", selectedRows);
        setLoadingModal(true);
        setModalShow(false);
        service.put(
            getHostByAppName(BILLING_NAME),
            `invoices/generate_invoices`,
            callbackResEnableDisable,
            error => {
                console.log(error);
                setLoadingModal(false);
            },
            selectedRows.map(elem => ({...elem, status:[]}))
        );
        }

    const handlerCraValidateOrRefuseActionRh = (ids, status) => {
        console.log("in handlerEnableDisableEmployee action", ids, status);
        axios
            .put(`${getHostByAppName(CRA_NAME)}/${context}/validate_refuse_cras`, {
                status: status,
                ids: ids
            }, getConfigObject())
            .then(res => {
                console.log(res.data);
                if(status){
                    handlerGenerateInvoicesAction();
                }
                setModalSuccessShow(true);
            })
            .catch(function (error) {
                setModalErrorShow(true);
                console.log(error);
            })
            .finally(() => {
                setLoadingModal(false);
            });
    };

    const callbackRes = res => {
        setCras(res.data);
        setFiltered(res.data);
        setLoadingModal(false);
        setMaxContent(res.headers['x-content-max'])
    };

    const initList = () =>{
        handlerAdvancedSearch()
    }

    /*Initialization CRA List */
    React.useEffect(
        () => {
            if (!modalSuccessShow) {
                initList();
            }
        },
        [modalSuccessShow]
    );

    
    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Delete Action*/
                //actionsValue[0] = {...actionsValue[0], disabled: false};
                /*Activate : validate Action*/
                actionsValue[1] = {...actionsValue[1], disabled: false};
                /*Activate : refuse Action*/
                actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : generate invoices Action*/
                actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    /*Activate : Edit Action*/
                    actionsValue[0] = {
                        ...actionsValue[0],
                        href: `/allTimesheets/${selectedRows[0].id}/validate`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);
                console.log("actions list : ", actionsValue);
                setAdvancedSearchShow(false);
            }
        },
        [selectedRows.length]
    );

    React.useEffect(()=>{     
        handlerAdvancedSearch(currentFilters, currentPaginationPerRow, currentPage);
    },[currentPaginationPerRow,currentPage, currentFilters])

    const handlerActivateAction = () => {
        console.log("in Activate action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), false);
    };

    const callbackResEnableDisable = () => {
        setLoadingModal(false);
        setModalSuccessShow(true);
    };

    const handlerEnableDisable = (ids, status) => {
        console.log("in handlerEnableDisable action", ids, status);
        service.put(
            getHostByAppName(CRA_NAME),
            `${context}/enable_disable_invoices`,
            callbackResEnableDisable,
            error => {
                console.log(error);
                setLoadingModal(false);
            },
            {
                status: status,
                ids: ids
            }
        );
    };

    const setNewFilters =  (data) => {
        setCurrentFilters(data)
    }

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }

        if(page == null){
            page = defaultPage;
        }
        console.log("handlerAdvancedSearch", data);
        setLoadingModal(true);
        service.search(
            getHostByAppName(CRA_NAME),
            `${context}/advanced_search/get?size=${size}&page=${page}`,
            callbackRes,
            (error) => {
                console.log(error);
                setLoadingModal(false);
            },
            {...data, organization: GetOrganizationIdFromSession()}
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        console.log("handlerCancelAdvancedSearch");
        initList();
    }, []);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const actionsComponent = (
        <div className="flex"> 
            <AdaButton style={{marginRight: 10}} onClick={() => props.history.push("allTimesheets/export")} >
                <IntlMessages id="action.export.silae"/>
            </AdaButton> 
            <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                        showActions={{exportExcel:false, add:false}} host={`${getHostByAppName(CRA_NAME)}`}/>
        </div>
        
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsAllCra}
            items={cras}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && <AdvancedSearchRH handlerAdvancedSearch={setNewFilters} showCollab={true}
                                                                       handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}/>}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsAllCra}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <br/>
                            {selectedRows.length === 1 &&
                                <DetailsTimeSheet timeSheetId={selectedRows.length === 1 ? selectedRows[0].id : null}/>
                            }

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>

                            <SweetAlert
                                onClick={props.processAction}
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Confirmation"
                                onConfirm={() => handlerGenerateInvoicesAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                            <SweetAlert
                                show={modalErrorShow}
                                onConfirm={() => setModalErrorShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                                error
                            >
                                <IntlMessages id="cra.validation.error"/>
                            </SweetAlert>

                            <Modal
                                show={loadingModal}
                                size="sm"
                                centered
                                backdrop={"static"}
                            >
                                <div className="align-self-center ">
                                    <Row className="pt-3">
                                        <Col>
                                            <Spinner animation="border" color="warning" role="status"
                                                     style={{width: '5rem', height: '5rem'}}/>
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 pb-2"><Col>
                                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                            Loading ...
                                        </div>
                                    </Col></Row>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(AllTimesheets);
