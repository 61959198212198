import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {FormGroup, Input, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {getLabelMonth} from "../../commun/commun.function";
const context = "timesheets";
export default function DateChoice(props) {
    let history = useHistory();
    const {register, handleSubmit, errors, setValue} = useForm();
    const [errorMessage, setErrorMessage] = useState("");
    const [dateList, setDateList] = useState(getList());

    function getList() {
        let list = []
        let actualMonth = new Date().getMonth()
        for(let i = 0; i<3 ; i++){
            list.push(
                {
                    key: i,
                    label: `${getLabelMonth(actualMonth - i)} / ${ (actualMonth === 0 && i!==0) || (actualMonth === 1 && i>1)  || (actualMonth === 2 && i>2) ? new Date().getFullYear()-1 : new Date().getFullYear()}`,
                    value: `${getValue(actualMonth -i)}/${ (actualMonth === 0 && i!==0) || (actualMonth === 1 && i>1)  || (actualMonth === 2 && i>2) ? new Date().getFullYear()-1 : new Date().getFullYear()}`,
                    obj: {month: getValue(actualMonth -i), year: (actualMonth === 0 && i!==0) || (actualMonth === 1 && i>1)  || (actualMonth === 2 && i>2) ? new Date().getFullYear()-1 : new Date().getFullYear()}
                }
            )
        }
        console.log("buildList",list)
        return list;
    }

    function getValue(number) {
        console.log("in getLabel", number)
        switch (number) {
            case -3 : return 10;
            case -2 : return 11;
            case -1 : return 12;

            default : return number+1;
        }
    }

    const onSubmit = data => {
        console.log("in modal submit", data);
        props.processAction(data.monthYear);
    };

    useEffect(() => {
        console.log("in change date use effect");
        register({name: "monthYear"});
        setValue("monthYear", {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        });
    }, []);

    const handleChangeValueDate = selectedOption => {
        console.log("monthYear", selectedOption);
        setValue("monthYear", selectedOption.obj);
    };

    const closePopUp = () => {
        setErrorMessage("");
        props.onHide();
    };

    return (
        <Modal
            {...props}
            size="l"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop={"static"}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <IntlMessages id="cra.new"/>
                </Modal.Title>
            </Modal.Header>
            <div>
                <div>
                    <div className="row ma-0">
                        <div className="col-sm-12 ptb-15">
                            <div className="roe-card-style">
                                <div className="roe-card-body col-sm-12">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        {props.message !== "" &&<Row className="pb-2">
                                            <Col>
                                               <div className="display-5 c-text-danger">{props.message}</div>
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col>
                                                <AdaSelect
                                                    name="quantityUnit"
                                                    errors={errors}
                                                    defaultValue={{
                                                        key: 0,
                                                        label: `${getLabelMonth(new Date().getMonth())} / ${new Date().getFullYear()}`,
                                                        value: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
                                                        obj: {
                                                            month: new Date().getMonth() + 1,
                                                            year: new Date().getFullYear()
                                                        }
                                                    }}
                                                    options={dateList}
                                                    onChange={handleChangeValueDate}
                                                    innerRef={register}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-3">
                                            <Col className="col-sm-10">
                                                <div className="display-6 c-text-danger">
                                                    {errorMessage}
                                                </div>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Row className="pl-5">
                                                        <Col className="col-sm-1"></Col>

                                                        <Col>
                                                            <AdaButton
                                                                className="c-btn c-danger form-button mt-15 fs-16 demi-bold-text"
                                                                onClick={()=>history.push(`/${context}`)}
                                                            >
                                                                <IntlMessages id="action.common.cancel"/>
                                                            </AdaButton>
                                                        </Col>

                                                        <Col>
                                                            <AdaButton
                                                                className="c-btn c-warning form-button mt-15 fs-16 demi-bold-text"
                                                                type="submit"
                                                            >
                                                                <IntlMessages id="action.common.submit"/>
                                                            </AdaButton>
                                                        </Col>


                                                        <Col className="col-sm-2"></Col>
                                                    </Row>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal.Footer/>
        </Modal>
    );
}
