export function getLabelMonth(number) {
    console.log("in getLabel", number)
    switch (number) {
        case 0 : return "Janvier";
        case 1 : return "Février";
        case 2 : return "Mars";
        case 3 : return "Avril";
        case 4 : return "Mai";
        case 5 : return "Juin";
        case 6 : return "Juillet";
        case 7 : return "Aôut";
        case 8 : return "Septembre";
        case 9 : return "Octobre";
        case -3 : return "Octobre";
        case 10 : return "Novembre";
        case -2 : return "Novembre";
        case -1 : return "Décembre";
        case 11 : return "Décembre";

        default : return number;
    }
}