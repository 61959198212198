import React, {useEffect, useState} from "react";
import Calender from "../../commun/Calender";
import {CRA_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../../service/host";
import Steps from "../../components/steps";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as service from "../../../service/crud.service";
import ActionCra from "../../components/cra.actions";
import {useHistory} from "react-router-dom";
import DetailsTimeSheet from "../details/details.timesheet";

const context = "timesheets";

export default function CraUpdateForm(props) {
    const [timeSheet, setTimeSheet] = useState({});
    const [events, setEvents] = useState([]);
    const [defaultStartDate, setDefaultStartDate] = useState(null);
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const [dateChoice, setDateChoice] = useState({});
    const [edit, setEdit] = useState(true);
    const [periodTimes, setPeriodTimes] = useState([])
    const [hdDays, setHdDays] = useState([])
    const [rhProfile, setRhProfile] = useState(false);

    let history = useHistory();
    const timesheetId = initTimesheetId();

    useEffect(() => {
        service.get(
            getHostByAppName(NOMENCLATURE_NAME),
            `period_times`,
            callBackPeriod,
            error => console.log(error)
        );

        service.get(
            getHostByAppName(NOMENCLATURE_NAME),
            `not_working_days/`,
            callBackNotWorkingDay,
            error => console.log(error)
        );

    }, [])

    const callBackNotWorkingDay = (res) =>{
        let list = [];
        if (Array.isArray(res)) {
            list = res.map((obj,key) => ({
                id    : key,
                title : obj.name,
                allDay: true,
                start : new Date(obj.dateFormat),
                end   : new Date(obj.dateFormat),
                JF : true,
                hd : false,
                unit : 1
            }))
        }
        setHdDays(list)
    }

    const callBackPeriod = (res) =>{
        let list = [];
        if (Array.isArray(res)) {
            list = res.map(obj => ({...obj, unit : obj.code === "JOU" ? 1 : 0.5}))
        }
        setPeriodTimes(list)
    }

    function initTimesheetId() {
        const {
            match: {params}
        } = props;
        return params.timesheetsId;
    }

    React.useEffect(
        () => {
            if (timesheetId !== undefined) {
                refreshTimeSheetObject(timesheetId);
            }
        },
        [timesheetId]
    );

    const refreshTimeSheetObject = (timesheetId) =>{
        service.getById(
            getHostByAppName(CRA_NAME),
            context,
            callBackResGetById,
            error => console.log(error),
            timesheetId
        );
    }

    const callBackResGetById = data => {
        if(!timeSheet.id) {
            initiateTimeSheet({month: data.month, year: data.year})
        }
        setTimeSheet(data)
    }

    const initiateTimeSheet = value => {
        setDefaultStartDate(null);
        setDateChoice(value);
        setDefaultStartDate(new Date(value.year, value.month - 1, 1));

    };

    React.useEffect(
        () => {
            if(periodTimes && periodTimes.length > 0)
            findAllTimeSheetItems();
        },
        [timeSheet,periodTimes]
    );

    const findAllTimeSheetItems = () =>{
        if (timeSheet !== undefined && timeSheet.id !== undefined) {
            service.get(
                getHostByAppName(CRA_NAME),
                `timesheetitems/mappedFindBy/timesheet/${timeSheet.id}`,
                callBackRes,
                error => console.log(error)
            );
        }
    }


    const callBackResPost = res => {
        setTimeSheet(res.data);
    };


    useEffect(
        () => {
            if (defaultStartDate !== null) {
                let startDate = new Date(defaultStartDate);
                let endDate = new Date(
                    new Date(startDate.setMonth(defaultStartDate.getMonth() + 1)) - 1
                );
                setDefaultEndDate(endDate);
            } else {
                setDefaultEndDate(null);
            }
        },
        [defaultStartDate]
    );

    const autoGenerateTimeSheet = () => {
        service.get(
            getHostByAppName(CRA_NAME),
            `${context}/init/${dateChoice.month}/${dateChoice.year}`,
            callBackRes,
            error => console.log(error)
        );
    };

    const cancelCreate = () => {
    };

    const format = date => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        return today;
    };

    const getObjById = (id, list) => {
        let objNomenclature = {};
        if (Array.isArray(list) && list.length > 0) {
            list.forEach(obj => {
                if (obj.id === id)
                    objNomenclature = obj;
            })
        }
        return objNomenclature;
    }

    const mapTimeSheetItemToData = (timeSheetItem) =>{
        return {
            id: timeSheetItem.id,
            title: getTitle(timeSheetItem),
            allDay: true,
            start: new Date(timeSheetItem.date),
            end: new Date(timeSheetItem.date),
            hd: timeSheetItem.dayOff,
            JF: timeSheetItem.notWorkingDay,
            periodTimeModel: timeSheetItem.periodTimeModel,
            timeSheetType: timeSheetItem.timeSheetType,
            leave: timeSheetItem.leave,
            assignment: timeSheetItem.assignment,
            unit : getObjById(timeSheetItem.periodTimeModel, periodTimes).unit

        }
    }

    const callBackRes = timeSheetItems => {
        let listEvents = [];
        if (timeSheetItems !== undefined && timeSheetItems.length > 0) {
            timeSheetItems.map((timeSheetItem, key) => {

                listEvents.push(mapTimeSheetItemToData(timeSheetItem));

            });
        }
        setEvents(listEvents);

    };

    function getTitle(timeSheet) {
        if ((!timeSheet.notWorkingDay && !timeSheet.dayOff)) {
            return timeSheet.periodTimeModelName + " " + timeSheet.timeSheetTypeName;
        } else {
            if (timeSheet.notWorkingDay) {
                return timeSheet.timeSheetTypeName;
            }

            if (timeSheet.dayOff) {
                return timeSheet.leaveName
            }
        }
        return ""
    }

    const handlerSaveItem = data => {
        service.post(
            getHostByAppName(CRA_NAME),
            `timesheetitems`,
            callbackItemRes,
            callbackError,
            mapDataToTimeSheetItem(data)
        );
    };

    const handlerEditItem = data => {
        service.put(
            getHostByAppName(CRA_NAME),
            `timesheetitems/${data.id}`,
            callbackItemUpdateRes,
            callbackError,
            {...mapDataToTimeSheetItem(data), id: data.id}
        );
    };

    const handlerDeleteItem = data => {
        service.deletes(
            getHostByAppName(CRA_NAME),
            `timesheetitems`,
            callbackItemUpdateRes,
            callbackError,
            [data.id]
        );
    };

    const mapDataToTimeSheetItem = (data) => {
        return {
            date: format(data.start),
            periodTimeModel: data.periodTimeModel,
            dayOff: data.hd,
            notWorkingDay: data.JF,
            assignment: data.assignment,
            leave: data.leave,
            timeSheetType: data.timeSheetType,
            relatedTo: timeSheet.id,
            unit: getObjById(data.periodTimeModel, periodTimes).unit
        }
    }


    const callbackItemUpdateRes = res => {
        findAllTimeSheetItems();
        refreshTimeSheetObject(timeSheet.id)
    };

    const callbackItemRes = res => {
        setEvents(pre => [...pre, mapTimeSheetItemToData(res.data)]);
    };

    const callbackError = error => {
        console.log("Error save ", error)
    };

    const isEditableDate = ()=>{
        console.log("timesheetDate ",new Date(timeSheet.timeSheetDate))
        console.log("date du jour ",new Date())
        if(new Date(timeSheet.timeSheetDate) < new Date()){
            console.log("date de modification depassé")
            return false;
        }
        console.log("date de modification non depassé")
        return true;
    }

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <Row>
                                <Col className="col-sm-5 pl-5">
                                    <div>
                                        <span style={{fontSize: 16}}/>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col className="col-sm-7">
                                    <Row>
                                        <Col>
                                            <Steps
                                                steps={
                                                    timeSheet.status !== undefined ? timeSheet.status : []
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-sm-5">
                                    <ActionCra edit={true} context={context} setTimeSheet={setTimeSheet}
                                               timeSheet={timeSheet} rh={false}
                                               autoGenerateTimeSheet={autoGenerateTimeSheet}
                                               changeMonth={() => setEdit(true)}
                                               initiateTimeSheet={() => initiateTimeSheet(dateChoice)}

                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col className="col-sm-12">
                                    {timeSheet && timeSheet.id &&
                                    <DetailsTimeSheet timeSheetId={timeSheet && timeSheet.id ? timeSheet.id : null} events={events}/>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {defaultStartDate !== null &&
                                    defaultEndDate !== null && (
                                        <Calender rhValidation={timeSheet.rhValidation} editableDate={true}
                                            events={events} setEvents={setEvents} handlerSaveItem={handlerSaveItem} handlerEditItem={handlerEditItem} handlerDeleteItem={handlerDeleteItem}
                                            defaultStartDate={defaultStartDate}
                                            defaultEndDate={defaultEndDate} edit={edit}
                                        />
                                    )}
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
