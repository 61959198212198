import React, {useEffect} from 'react';
import RoyTooltip from "../common/RoyTooltip";
import moment from 'moment';

  const CalendarHeader = (toolbar, actions) => {

      useEffect(()=>{
          console.log("toolbar",toolbar)
      },[])

    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };
  
    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };
  
    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };
  
    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span><b>{date.format('MMMM')}</b><span> {date.format('YYYY')}</span></span>
      );
    };

    return (
        <div className="calender-toolbar-container">

            <div className="navigation-buttons">
                <div>

                </div>
                <div className='label-date'>{label()}</div>
                <div>

                </div>
            </div>
        </div >
    );
  };
export default CalendarHeader;