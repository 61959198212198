import IntlMessages from "../../../helpers/intlMessages";
import React from "react";

export const  columnsEmployee = [

    {
        name: <IntlMessages
            id="employee.matricule"
        />,
        selector: "code",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="employee.full.name"
        />,
        selector: "name",
        sortable: false,
        filtrable: true,
        cell : row => <h2>{row.firstName} {row.lastName}</h2>
    },
    {
        name: <IntlMessages
            id="employee.email"
        />,
        selector: "email",
        sortable: false,
        filtrable: true,
    }

];

