import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import {Controller, useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import {CRA_NAME, getHostByAppName} from "../../../service/host";
import {AdaSelect, AdaButton} from "@adaming/ada-react-component";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import axios from "axios";
import {getConfigObject} from "@adaming/ada-react-component";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

export default function ExportForm(props) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [object, setObject] = useState({year: "", month: "", organization: GetOrganizationIdFromSession()});
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
       
        service.getAllObj(getHostByAppName(CRA_NAME), `timesheets/getYear`, setYearList)
        let listMonth = [];
        for (let i =1; i<13; i++){
            listMonth.push({
                key:i,
                label: <IntlMessages id={`month.${i}`}/>,
                value : i
            })
        }
        setMonthList(listMonth);
    }, [])

    const contexte = 'timesheetitems'
    const callbackError = error => {
        console.log("error to print", error);
        setErrorMessage("Erreur lors du téléchargement : "+ error.message);
        setModalErrorShow(true)
    };

    const onExport =() =>{
        axios.get(getHostByAppName(CRA_NAME)+'/'+contexte+'/export/silae/byYearAndMonth/'+object.month+'/'+object.year, getConfigObject()).then(res => {
            const blob = new Blob([res.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'silae-'+object.month+'-'+object.year+'.csv';
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(function (error) {
            callbackError(error); 
        });
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("year", selectedOption.key);
            setObject({...object, year:selectedOption.key})
        } else {
            setValue("year", null);
            setObject({...object, year:null})
        }
    };

    const handleChangeMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("month", selectedOption.key);
            setObject({...object, month: selectedOption.key})
        } else {
            setValue("month", null);
            setObject({...object, month: null})
        }
    };

    return (
    <div>
        <div className="row ma-0">
            <div className="col-sm-12 ptb-15 full-height">
                <div className="roe-card-style">
                    <div className="roe-card-body">
                        <Row>
                            <Col className="col-sm-5 pl-5">
                                <FormGroup>
                                    <IntlMessages id="month.label"/>
                                    <Controller
                                        as={<AdaSelect name={"month"}/>}
                                        name={"month"}
                                        isClearable
                                        errors={errors}
                                        options={monthList}
                                        onChange={handleChangeMonth}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <IntlMessages id="common.year"/>
                                    <Controller
                                        as={<AdaSelect name={"year"}/>}
                                        name={"year"}
                                        isClearable
                                        errors={errors}
                                        options={yearList}
                                        onChange={handleChangeYear}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="pt-4 col-sm-3">
                                <FormGroup>
                                
                                    <AdaButton style={{height: 35}} className="c-danger col-xl-5" onClick={() => props.history.push("/allTimesheets")}>
                                        <IntlMessages id="action.back"/>
                                    </AdaButton>
                                    <AdaButton onClick={() => onExport()}
                                        className="c-warning col-xl-6"
                                        style={{marginLeft: 10, height: 35}} 
                                    >
                                        <IntlMessages id="action.generate.csv"/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert
                show={modalErrorShow}
                onConfirm={() => setModalErrorShow(false)}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
                error
            >
                {errorMessage}
        </SweetAlert>
    </div>
    )
}