import React, {useEffect, useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import DatePicker from "react-datepicker";
import DatepickerWrapper from "../../components/forms/alldatepickers/datepicker.style";
import * as service from "../../service/crud.service";
import {ASSIGNMENT_NAME, getHostByAppName, NOMENCLATURE_NAME} from "../../service/host";
import AdaSelectBuilder from "../../views/components/ada.select.builder";
import {useForm} from "react-hook-form";

const EventDialog = (props) => {

    const {className, modal, toggleModal, event, action, eventSubmitHandler, eventDeleteHandler} = props
    const [title, setTitle] = useState(event ? event.title : "")
    const [desc, setDesc] = useState(event && event.hasOwnProperty('desc') ? event.desc : "")
    const [start, setStart] = useState(event ? event.start : new Date());
    const [end, setEnd] = useState(event ? event.end : new Date());
    const [allDay, setAllDay] = useState(event ? event.allDay ? 'yes' : 'no' : 'no')
//
    const {register, handleSubmit, errors, setValue, control} = useForm();

    const [timeSheetTypeState, setTimeSheetTypeState] = useState(event ? event.timeSheetType ? event.timeSheetType : {} : {});
    const [periodTimesState, setPeriodTimesState] = useState(event ? event.periodTimeModel ? event.periodTimeModel : {} : {});
    const [assignment, setAssignment] = useState(event ? event.assignment ? event.assignment : {} : {});
    const [showAssignment, setShowAssignment] = useState(event ? event.assignment ? true : false : false);

    const [selectedCivilityObjects, setSelectedCivilityObjects] = useState({});

    const [timeSheetTypes, setTimeSheetTypes] = useState([])
    const [periodTimes, setPeriodTimes] = useState([])
    const [assignments, setAssignments] = useState([])
    const [mondatory, setMondatory] = useState("")


    const dispatch = (obj) => {
        if (obj.timeSheetType) {
            if (obj.timeSheetType === "5e951170a35fc0eeb8cbd93c") {
                setShowAssignment(true)
            } else {
                setShowAssignment(false)
                obj = {...obj, assignment: null}
            }
        }
        setSelectedCivilityObjects({...selectedCivilityObjects, ...obj});
    };

    useEffect(() => {
        setSelectedCivilityObjects({
            periodTimeModel: event ? event.periodTimeModel ? event.periodTimeModel : null : null,
            timeSheetType: event ? event.timeSheetType ? event.timeSheetType : null : null,
            assignment: event ? event.assignment ? event.assignment : null : null
        });
        service.get(
            getHostByAppName(NOMENCLATURE_NAME),
            `timesheet_types`,
            setTimeSheetTypes,
            error => console.log(error)
        );

        service.get(
            getHostByAppName(NOMENCLATURE_NAME),
            `period_times`,
            callBackPeriod,
            error => console.log(error)
        );

        if (event) {
            let date = format(event.start)
            service.get(
                getHostByAppName(ASSIGNMENT_NAME),
                `assignments/?beginDate=${date}&endDate=${date}&idCollaborator=5f7439a7a968dc5fbe4bca51`,
                callbacksResAssignment,
                error => console.log(error)
            );
        }
    }, [])

    const callBackPeriod = (res) =>{
        let list = [];
        if (Array.isArray(res)) {
            list = res.map(obj => ({...obj, unit : obj.code === "JOU" ? 1 : 0.5}))
        }
        setPeriodTimes(list)
    }

    const format = (date) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        return today;
    }

    const callbacksResAssignment = data => {
        let list = []
        if (Array.isArray(data)) {
            list = data.map(assig => ({id: assig.id, name: assig.directClient.clientName}));
        }
        setAssignments(list);
    }

    const getUnitEventsBySlot = (date, id) => {
        let unit = 0;

            if (action === "edit"){
                props.events
                    .filter((obj,index) =>
                        obj.start.getFullYear() === date.getFullYear() && obj.start.getMonth() === date.getMonth() && obj.start.getDate() === date.getDate() && obj.id !== id)
                    .map(obj => unit = unit + obj.unit)
            }else{
                props.events
                    .filter((obj,index) =>
                        obj.start.getFullYear() === date.getFullYear() && obj.start.getMonth() === date.getMonth() && obj.start.getDate() === date.getDate())
                    .map(obj => unit = unit + obj.unit)
            }
        return unit;
    }

    const eventHandler = (e) => {
        e.preventDefault();
        if (selectedCivilityObjects.periodTimeModel && selectedCivilityObjects.timeSheetType) {
            if ((getUnitEventsBySlot(event.start, event.id)+getObjById(selectedCivilityObjects.periodTimeModel, periodTimes).unit) <= 1) {
                const data = {
                    ...event,
                    id: action === "edit" ? event.id : Math.random(),
                    title: getTitle(selectedCivilityObjects),
                    hd: false,
                    JF: false,
                    periodTimeModel: selectedCivilityObjects.periodTimeModel,
                    timeSheetType: selectedCivilityObjects.timeSheetType,
                    leave: null,
                    assignment: selectedCivilityObjects.assignment,
                    unit: getObjById(selectedCivilityObjects.periodTimeModel, periodTimes).unit
                }
                setMondatory("")
                eventSubmitHandler(data, action)
            }else{
                setMondatory("Période invalide")
            }
        } else {
            setMondatory("(*) Champs obligatoires")
        }
    }

    const getCodeNomenclatureById = (id, list) => {
        let code = "NOTFOUND";
        if (Array.isArray(list) && list.length > 0) {
            list.forEach(obj => {
                if (obj.id === id)
                    code = obj.code;
            })
        }
        return code;
    }

    const getObjById = (id, list) => {
        let objNomenclature = {};
        if (Array.isArray(list) && list.length > 0) {
            list.forEach(obj => {
                if (obj.id === id)
                    objNomenclature = obj;
            })
        }
        return objNomenclature;
    }

    const getTitle = selectedCivilityObjects => {
        const timeSheetTypeObj = getObjById(selectedCivilityObjects.timeSheetType, timeSheetTypes);
        return getObjById(selectedCivilityObjects.periodTimeModel, periodTimes).abbreviation + " " + timeSheetTypeObj.abbreviation;
    }

    return (
        <div>
            <Modal
                centered
                isOpen={modal}
                toggle={() => toggleModal()}
                className={className}
            >
                <ModalBody>
                    <div className="fs-18 bold-text text-center">
                        Time sheet
                    </div>
                    <form onSubmit={eventHandler}>
                        <div className="mb-10">
                            <label className="fs-16 demi-bold-text c-text-danger">{mondatory}</label>
                        </div>
                        <div className="mb-10">
                            <label className="fs-16 demi-bold-text">Date</label>
                            <DatepickerWrapper {...props}>
                                <DatePicker
                                    selected={start}
                                    onChange={(date) => setStart(date)}
                                    showTimeSelect
                                    disabled
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    dateFormat="dd-MM-yyyy"
                                    timeCaption="time"
                                    className="custom-datepicker"
                                    calendarClassName="custom-calender-class"
                                />
                            </DatepickerWrapper>
                        </div>

                        <div className="mb-10">
                            <label className="fs-16 demi-bold-text">Type <b className="c-text-danger">(*)</b></label>
                            <AdaSelectBuilder errors={errors} register={register}
                                              setValue={setValue}
                                              uri={`${getHostByAppName(NOMENCLATURE_NAME)}/timesheet_types`}
                                              inputName={"timeSheetType"}
                                              optionsType={"string"}
                                              defaultId={timeSheetTypeState}
                                              control={control}
                                              dispatch={dispatch}/>
                        </div>

                        <div className="mb-10">
                            <label className="fs-16 demi-bold-text">Période <b className="c-text-danger">(*)</b></label>
                            <AdaSelectBuilder errors={errors} register={register}
                                              setValue={setValue}
                                              uri={`${getHostByAppName(NOMENCLATURE_NAME)}/period_times`}
                                              inputName={"periodTimeModel"}
                                              optionsType={"string"}
                                              defaultId={periodTimesState}
                                              control={control}
                                              dispatch={dispatch}/>
                        </div>

                        {assignments && assignments.length > 0 && showAssignment && <div className="mb-10">
                            <label className="fs-16 demi-bold-text">Client <b className="c-text-danger">(*)</b></label>
                            <AdaSelectBuilder errors={errors} register={register}
                                              setValue={setValue}
                                              options={assignments}
                                              inputName={"assignment"}
                                              optionsType={"string"}
                                              defaultId={assignment}
                                              control={control}
                                              dispatch={dispatch}/>
                        </div>}
                        <div className="pb-3">
                        <button
                            disabled={title === ""}
                            type="submit"
                            className="c-btn form-button mt-15 fs-16 demi-bold-text"
                        >
                            Valider
                        </button>
                        {
                            action === "edit" &&
                            <button
                                onClick={() => eventDeleteHandler(event)}
                                type="button"
                                className="c-btn c-warning form-button mt-15 fs-16 demi-bold-text"
                            >
                                Supprimer
                            </button>
                        }
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
}
export default EventDialog;
