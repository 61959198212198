import React, {useEffect, useState} from "react";

import {AdaButton} from "@adaming/ada-react-component";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../helpers/intlMessages";
import * as service from "../../service/crud.service";
import {CRA_NAME, getHostByAppName} from "../../service/host";

export default function BtnAction({
                                       setModalSuccessShow, timeSheet, setTimeSheet,
                                       context, btnName,className
                                   }) {
    const [approve, setApprove] = useState(false);
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const callbackRes = res => {
        console.log(res.data);
        setApprove(false);
        setTimeSheet(res.data);
        setModalSuccessShow(true);
    };

    const callbackError = error => {
        console.log("error to print", error.response.data.message);
        setErrorMessage(error.response.data.message)
        setModalErrorShow(true)
        setApprove(false);
    };

    const onSubmitApprove = () => {
        service.put(
            getHostByAppName(CRA_NAME),
            `${context}/${timeSheet.id}/`,
            callbackRes,
            callbackError
        );
    };

    return (
        <div>

            <AdaButton style={{height: 32, width:120}}
                className={className}
                onClick={() => setApprove(true)}
            >
                <i style={{fontSize: 12, fontWeight:"bold"}}> {btnName}</i>
            </AdaButton>

            <SweetAlert
                warning
                showCancel
                show={approve}
                confirmBtnText="Oui"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={"Confirmation"}
                onConfirm={() => onSubmitApprove()}
                onCancel={() => setApprove(false)}
            >
                <IntlMessages id="action.common.confirmation"/>
            </SweetAlert>

            <SweetAlert
                show={modalErrorShow}
                onConfirm={() => setModalErrorShow(false)}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
                error
            >
                {errorMessage}
            </SweetAlert>
        </div>
    );
}
