import React, {useState} from "react"
import Col from "react-bootstrap/Col";
import {AdaButton} from "@adaming/ada-react-component";
import Row from "react-bootstrap/Row";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "../../helpers/intlMessages";
import BtnAction from "./timesheet.approve";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import VisualizeDocumentAction from "./visualize.document";
import {CRA_NAME, EMPLOYEE_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../service/host";
import * as service from "../../service/crud.service";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

export default function ActionCra({autoGenerateTimeSheet, initiateTimeSheet, saveTimeSheet, changeMonth, edit, context, timeSheet, setTimeSheet, rh}) {

    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    const [modalLoadingShow, setModalLoadingShow] = useState(false);

    const loadInformationByAssignment = async assignmentId => {
        let objectToPrint = {}
        let employee = {}
        let transmitter = {}

        await service.getUri(`${getHostByAppName(CRA_NAME)}/timesheetitems/mappedFindByToPDF/timesheet/${timeSheet.id}`)
            .then(res => objectToPrint = res.data)
            .catch(
                error=>
                    {console.log("erro in loadInformationByAssignment",error);
                    setModalLoadingShow(false)}
            )

        await service.getUri(`${getHostByAppName(EMPLOYEE_NAME)}/employees/${timeSheet.employee.id}`)
            .then(res => employee = res.data)
            .catch(
                error=>
                {console.log("erro in loadInformationByAssignment",error);
                    setModalLoadingShow(false)}
            )

        await service.getUri(`${getHostByAppName(ORGANIZATION_NAME)}/organizations/${employee.organization}`)
            .then(res => transmitter = res.data)
            .catch(
                error=>
                {console.log("erro in loadInformationByAssignment",error);
                    setModalLoadingShow(false)}
            )


        await service.getUri(`${getHostByAppName(CRA_NAME)}/timesheetitems/mappedFindByToPDF/timesheet/${timeSheet.id}`)
            .then(res => objectToPrint = res.data)
            .catch(
                error=>
                {console.log("erro in loadInformationByAssignment",error);
                    setModalLoadingShow(false)}
            )
        console.log("ojectToPrint", objectToPrint)
        return {...objectToPrint, transmitter:transmitter}
    }

    return (
        <div>
            <ButtonGroup className="align-items-end">
                {!edit && <AdaButton style={{height: 32, width:180}}
                        className="c-btn ma-5 no-border c-outline-primary"
                        onClick={autoGenerateTimeSheet}>
                    <i style={{fontSize: 12, fontWeight:"bold"}}> Génération automatique</i>
                    </AdaButton> }

                {!edit && <AdaButton style={{height: 32, width:120}}
                    className="c-btn ma-5 no-border c-outline-AdaSubmit"
                        onClick={saveTimeSheet}>
                    <i style={{fontSize: 12, fontWeight:"bold"}}> Enregistrer</i>
                    </AdaButton>
                }

                {!edit && <AdaButton style={{height: 32, width:120}}
                    className="c-btn ma-5 no-border c-outline-warning"
                        onClick={changeMonth}>
                    <i style={{fontSize: 12, fontWeight:"bold"}}> Changer Mois</i>
                    </AdaButton>}

                {edit && <VisualizeDocumentAction
                    context={context} setModalLoadingShow={setModalLoadingShow}
                    setErrorMessage={(error)=>console.log(error)}
                    loadInformationByAssignment={loadInformationByAssignment}
                />}

                {edit && !rh && <BtnAction className={"c-btn ma-5 no-border c-outline-AdaSubmit"} timeSheet={timeSheet} setTimeSheet={setTimeSheet} context={`${context}/approve`} setModalSuccessShow={setModalSuccessShow} btnName={"Approuver"}/>}

                {edit && rh && <BtnAction className={"c-btn ma-5 no-border c-outline-AdaSubmit"} timeSheet={timeSheet} setTimeSheet={setTimeSheet} context={`${context}/validate`} setModalSuccessShow={setModalSuccessShow} btnName={"Valider"}/>}

                {edit && rh && <BtnAction className={"c-btn ma-5 no-border c-outline-AdaBack"} timeSheet={timeSheet} setTimeSheet={setTimeSheet} context={`${context}/refuse`} setModalSuccessShow={setModalSuccessShow} btnName={"Refuser"}/>}

            </ButtonGroup>


            <SweetAlert
                success
                show={modalSuccessShow}
                title="Success!"
                onConfirm={() => setModalSuccessShow(false)}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
            >
                <IntlMessages id="action.common.succes"/>
            </SweetAlert>

            <Modal
                show={modalLoadingShow}
                size="sm"
                centered
                backdrop={"static"}
            >
                <div className="align-self-center ">
                    <Row className="pt-3">
                        <Col>
                            <Spinner animation="border" color="warning" role="status"
                                     style={{width: '5rem', height: '5rem'}}/>
                        </Col>
                    </Row>
                    <Row className="pt-3 pb-2"><Col>
                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                            Loading ...
                        </div>
                    </Col></Row>
                </div>
            </Modal>
        </div>
    )
}
