import React, {useEffect, useState} from "react";
import Calender from "../../commun/Calender";
import {CRA_NAME, EMPLOYEE_NAME, getHostByAppName, LEAVE_NAME, NOMENCLATURE_NAME} from "../../../service/host";
import Steps from "../../components/steps";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as service from "../../../service/crud.service";
import DateChoice from "./choice.date";
import ActionCra from "../../components/cra.actions";
import {useHistory} from "react-router-dom";
import {GetUserFromSession} from "../../../service/session.service";

const context = "timesheets";

export default function CraForm(props) {
    const [timeSheet, setTimeSheet] = useState({});
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [defaultStartDate, setDefaultStartDate] = useState(null);
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const [dateChoice, setDateChoice] = useState({});
    const [periodTimes, setPeriodTimes] = useState([]);
    const [hdDays, setHdDays] = useState([])
    const [leaveDays, setLeaveDays] = useState([])
    const [user, setUser] = useState(GetUserFromSession)
    const [employee, setEmployee] = useState({})
    const [errorMessage, setErrorMessage] = useState("")

    let history = useHistory();

    useEffect(() => {

        service.get(
            getHostByAppName(NOMENCLATURE_NAME),
            `period_times`,
            callBackPeriod,
            error => console.log(error)
        );

        service.get(
            getHostByAppName(NOMENCLATURE_NAME),
            `not_working_days/`,
            callBackNotWorkingDay,
            error => console.log(error)
        );

    }, [])

    useEffect(() => {
        if (employee.id)
            service.get(
                getHostByAppName(LEAVE_NAME),
                `leaves/findByCollaborator/${employee.id}`,
                callBackLeaveDay,
                error => console.log(error)
            );
    }, [employee])

    useEffect(() => {
        if (user.id)
            service.search(
                getHostByAppName(EMPLOYEE_NAME),
                `employees/advanced_search/get`,
                callbackResEmpl,
                (error) => {
                    console.log(error);
                },
                {user: user.id}
            );
    }, [user])

    const callbackResEmpl = data => {
        console.log("in get emp by user", data)
        setEmployee(data && data.data && data.data.length > 0 ? {
            id: data.data[0].id,
            code: data.data[0].code,
            firstName: data.data[0].firstName,
            lastName: data.data[0].lastName,
            user: user.id,
            organization: data.data[0].organization
        } : {})
    }

    const callBackLeaveDay = (res) => {
        console.log("leave days",res)
        let list = [];
        if (Array.isArray(res)) {
            res.forEach(leave => {
                if (Array.isArray(leave.leaveItems)) {
                    list = leave.leaveItems.map((leaveItem, key) => (
                        {
                            id: key,
                            title: leaveItem.periodTime.code.concat(". ", leave.leaveType.name),
                            allDay: true,
                            start: new Date(leaveItem.date),
                            end: new Date(leaveItem.date),
                            JF: false,
                            hd: true,
                            leave: leave.id,
                            leaveType: leave.leaveType.id,
                            unit: leaveItem.halfDay ? 0.5 : 1
                        }))
                }
            })
        }
        console.log("list leave", list)
        setLeaveDays(list)
    }

    const callBackNotWorkingDay = (res) => {
        let list = [];
        if (Array.isArray(res)) {
            list = res.map((obj, key) => ({
                id: key,
                title: obj.name,
                allDay: true,
                start: new Date(obj.dateFormat),
                end: new Date(obj.dateFormat),
                JF: true,
                hd: false,
                unit: 1
            }))
        }
        setHdDays(list)
    }

    const callBackPeriod = (res) => {
        let list = [];
        if (Array.isArray(res)) {
            console.log("period list", res)
            list = res.map(obj => ({...obj, unit: obj.code === "JOU" ? 1 : 0.5}))
        }
        setPeriodTimes(list)
    }

    const getObjById = (id, list) => {
        let objNomenclature = {};
        if (Array.isArray(list) && list.length > 0) {
            list.forEach(obj => {
                if (obj.id === id)
                    objNomenclature = obj;
            })
        }
        return objNomenclature;
    }

    const saveTimeSheet = () => {
        if (dateChoice !== null) {
            service.post(
                getHostByAppName(CRA_NAME),
                `${context}/create/`,
                callBackResPost,
                error => console.log(error.response.data.message), mapData()
            );
        }
    };


    const mapData = () => {
        let listItems = [];
        listItems = events.map(item => ({
            date: format(item.start),
            periodTimeModel: item.periodTimeModel,
            dayOff: item.hd,
            notWorkingDay: item.JF,
            assignment: item.assignment,
            leave: item.leave,
            leaveType: item.leaveType,
            timeSheetType: item.timeSheetType,
            unit: item.unit,
            leaveName: item.hd ? item.title : null,
            client: item.client
        }))
        return {
            name: `CRA ${dateChoice.month}-${dateChoice.year}`,
            month: dateChoice.month,
            year: dateChoice.year,
            timeSheetItems: listItems,
            employee: employee,
            status: []

        }
    }

    const callBackResPost = (res) => {
        setTimeSheet(res.data);
        history.push(`/${context}/${res.data.id}/edit`);
    }

    const initiateTimeSheet = value => {
        setDefaultStartDate(null);
        setDateChoice(value);
        setDefaultStartDate(new Date(value.year, value.month - 1, 1));
        setEvents([...hdDays, ...leaveDays]);
        //setShowModal(false);
        // Desactivate autogeneration
        //autoGenerateTimeSheet(value);
        setShowModal(false);
        setTimeSheet({
            status : [{ code: "CR", label: "Création", finished: true },
                { code: "SV", label: "Enregistré", finished: false },
                { code: "AP", label: "Approuvé", finished: false },
                { code: "VL", label: "Validé", finished: false },
                { code: "CC", label: "Annulé", finished: false },
                { code: "RF", label: "Refusé", finished: false }],
            ...timeSheet
        });
    };

    useEffect(() => {
        if (defaultStartDate !== null) {
            let startDate = new Date(defaultStartDate);
            let endDate = new Date((new Date(startDate.setMonth(defaultStartDate.getMonth() + 1))) - 1);
            setDefaultEndDate(endDate)
        } else {
            setDefaultEndDate(null)
        }
    }, [defaultStartDate])

    const autoGenerateTimeSheet = (dateChoice) => {
        console.log("generate CRA by date", dateChoice)
        service.get(
            getHostByAppName(CRA_NAME),
            `${context}/init/${dateChoice.month}/${dateChoice.year}/${employee.id}`,
            callBackRes,
            error => setErrorMessage(error.response.data.message)
        );
    };

    const cancelCreate = () => {
    };

    const format = (date) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        return today;
    }

    const callBackRes = data => {
        setErrorMessage("")
        let listEvents = [];
        if (data !== undefined && data !== null) {
            setTimeSheet(data);
            if (data.timeSheetItems !== undefined && data.timeSheetItems.length > 0) {
                data.timeSheetItems.map((timeSheet, key) => {
                    if (
                        (new Date(timeSheet.date).getDay() !== 6 &&
                            new Date(timeSheet.date).getDay() !== 0) ||
                        timeSheet.notWorkingDay
                    ) {
                        listEvents.push({
                            id: key,
                            title: timeSheet.periodTimeModelName + " " + timeSheet.timeSheetTypeName,
                            allDay: true,
                            start: new Date(timeSheet.date),
                            end: new Date(timeSheet.date),
                            hd: timeSheet.dayOff,
                            JF: timeSheet.notWorkingDay,
                            periodTimeModel: timeSheet.periodTimeModel,
                            timeSheetType: timeSheet.timeSheetType,
                            leave: timeSheet.leave,
                            assignment: timeSheet.assignment,
                            unit: getObjById(timeSheet.periodTimeModel, periodTimes).unit,
                            client: timeSheet.client

                        });
                    }
                });
            }
            setEvents([...listEvents, ...hdDays, ...leaveDays]);
            setShowModal(false);
        }
    };

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <Row>
                                <Col className="col-sm-5 pl-5">
                                    <div>
                                        <span style={{fontSize: 16}}/>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <Row>

                                <Col className="col-sm-7">
                                    <Row>
                                        <Col>
                                            <Steps
                                                steps={
                                                    timeSheet.status ? timeSheet.status : []
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-sm-5">
                                    <ActionCra
                                        autoGenerateTimeSheet={() => autoGenerateTimeSheet(dateChoice)}
                                        changeMonth={() => setShowModal(true)}
                                        initiateTimeSheet={() => initiateTimeSheet(dateChoice)}
                                        saveTimeSheet={() => saveTimeSheet()}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {defaultStartDate !== null && defaultEndDate !== null && (
                                        <Calender
                                            events={events}
                                            setEvents={setEvents}
                                            defaultStartDate={defaultStartDate}
                                            defaultEndDate={defaultEndDate}
                                        />
                                    )}
                                </Col>
                            </Row>

                            {showModal && (
                                <DateChoice
                                    show={showModal} message={errorMessage}
                                    onHide={() => setShowModal(false)}
                                    processAction={initiateTimeSheet}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
