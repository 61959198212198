import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import {Controller, useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import * as specificService from "../../../service/employee.service";
import {CRA_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import {AdaSelect, AdaButton} from "@adaming/ada-react-component";
import {months} from "./months";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

export default function AdvancedSearch({handlerAdvancedSearch, handlerCancelAdvancedSearch, showCollab}) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [object, setObject] = useState({employee: null, year: "", month: "", organization: GetOrganizationIdFromSession()});
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([]);


    useEffect(() => {
        if(showCollab)
            specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
        service.getAllObj(getHostByAppName(CRA_NAME), `timesheets/getYear`, setYearList)
        let listMonth = [];
        for (let i =1; i<13; i++){
            listMonth.push({
                key:i,
                label: <IntlMessages id={`month.${i}`}/>,
                value : i
            })
        }
        console.log("list month",listMonth)
        setMonthList(listMonth);
    }, [])

    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            setObject({...object, employee:selectedOption.key})
        } else {
            setValue("employee", null);
            setObject({...object, employee:null})
        }
    };

    const onSubmit =(data) =>{
        console.log("data search",data)
        console.log("object search",object)
        handlerAdvancedSearch(object);
    }

    const clear =() =>{
        reset({employee: null, year: "", month: "", organization: GetOrganizationIdFromSession()});
        setObject({organization: GetOrganizationIdFromSession()})
        handlerCancelAdvancedSearch();
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("year", selectedOption.key);
            setObject({...object, year:selectedOption.key})
        } else {
            setValue("year", null);
            setObject({...object, year:null})
        }
    };

    const handleChangeMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("month", selectedOption);
        if (selectedOption !== null) {
            setValue("month", selectedOption.key);
            setObject({...object, month: selectedOption.key})
        } else {
            setValue("month", null);
            setObject({...object, month: null})
        }
    };

    return (<div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FormGroup>
                        <IntlMessages id="month.label"/>
                        <Controller
                            as={<AdaSelect name={"month"}/>}
                            name={"month"}
                            isClearable
                            errors={errors}
                            options={monthList}
                            onChange={handleChangeMonth}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <IntlMessages id="common.year"/>
                        <Controller
                            as={<AdaSelect name={"year"}/>}
                            name={"year"}
                            isClearable
                            errors={errors}
                            options={yearList}
                            onChange={handleChangeYear}
                            control={control}
                            innerRef={register}
                        />
                    </FormGroup>
                </Col>

                <Col className="pt-4 col-sm-3">
                    <FormGroup>
                        <AdaButton style={{height: 35}} className="c-danger col-xl-5" onClick={()=>clear()}>
                            <IntlMessages id="action.common.cancel"/>
                        </AdaButton>
                        <AdaButton
                            className="c-warning col-xl-6"
                            type="submit"
                            style={{marginLeft: 10, height: 35}}
                        >
                            <IntlMessages id="common.search"/>
                        </AdaButton>
                    </FormGroup>
                </Col>



            </Row>

        </form>
    </div>)
}