const CRM_HOST = "http://localhost:8081";
const EMPLOYEE_HOST = "http://localhost:8082";
const ORGANIZATION_HOST = "http://localhost:8083";
const BILLING_HOST = "http://localhost:8084";
const ASSIGNMENT_HOST = "http://localhost:8085";
const CRA_HOST = "http://localhost:8087";
const DOCUMENT_HOST = "http://localhost:8091";
const NOMENCLATURE_HOST = "http://localhost:8090";
const LEAVE_HOST = "http://localhost:8092";

export const ASSIGNMENT_NAME = "ASSIGNMENT";
export const CRM_NAME = "CRM";
export const EMPLOYEE_NAME = "EMPLOYEE";
export const ORGANIZATION_NAME = "ORGANIZATION";
export const BILLING_NAME = "BILLING";
export const DOCUMENT_NAME = "DOCUMENT";
export const CRA_NAME = "CRA";
export const NOMENCLATURE_NAME = "NOMENCLATURE";
export const LEAVE_NAME = "LEAVE";

export const getHostByAppName = appName => {
    if (process.env.NODE_ENV === 'local') {
        switch (appName) {
            case ASSIGNMENT_NAME:
                return ASSIGNMENT_HOST;
                break;

            case CRM_NAME:
                return CRM_HOST;
                break;

            case EMPLOYEE_NAME:
                return EMPLOYEE_HOST;
                break;

            case ORGANIZATION_NAME:
                return ORGANIZATION_HOST;
                break;

            case BILLING_NAME:
                return BILLING_HOST;
                break;

            case DOCUMENT_NAME:
                return DOCUMENT_HOST;
                break;

            case CRA_NAME:
                return CRA_HOST;
                break;

            case NOMENCLATURE_NAME:
                return NOMENCLATURE_HOST;
                break;

            case LEAVE_NAME:
                return LEAVE_HOST;
                break;

            default : return ""
        }
    }else{
        switch (appName) {
            case ASSIGNMENT_NAME:
                return `${process.env.REACT_APP_API_URL}/asignment-service`;
                break;

            case CRM_NAME:
                return `${process.env.REACT_APP_API_URL}/client-service`;
                break;

            case EMPLOYEE_NAME:
                return `${process.env.REACT_APP_API_URL}/employee-service`;
                break;

            case ORGANIZATION_NAME:
                return `${process.env.REACT_APP_API_URL}/organization-service`;
                break;

            case BILLING_NAME:
                return `${process.env.REACT_APP_API_URL}/billing-service`;
                break;

            case DOCUMENT_NAME:
                return `${process.env.REACT_APP_API_URL}/document-service`;
                break;

            case CRA_NAME:
                return `${process.env.REACT_APP_API_URL}/cra-service`;
                break;

            case NOMENCLATURE_NAME:
                return `${process.env.REACT_APP_API_URL}/nomenclature-service`;
                break;
            case LEAVE_NAME:
                return `${process.env.REACT_APP_API_URL}/leave-service`;
                break;
            default : return ""
        }
    }
};
