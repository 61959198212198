import React from "react";

import {AdaButton} from "@adaming/ada-react-component";
import * as service from "../../service/crud.service";
import {DOCUMENT_NAME, getHostByAppName} from "../../service/host";

export default function VisualizeDocumentAction({
                                                    setErrorMessage,setModalLoadingShow,
                                                    context, loadInformationByAssignment
                                                }) {

    function dataURLtoFile(dataUrl, filename, type) {

        let   bstr = atob(dataUrl),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: type});
    }

    const openDocument = (document) => {
        let file = dataURLtoFile(document.contents, document.documentName, document.documentType);
        console.log("doc", file)
        let fileURL = URL.createObjectURL(file)
        console.log("fileURL", fileURL)
        window.open(fileURL);
    }

    const callbackRes = res => {
        console.log("doc",res.data)
        openDocument(res.data);
        setModalLoadingShow(false)
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        console.log("error to print", error.message);
        setModalLoadingShow(false)
    };

    const handlerClickVisualize = async () => {
        setModalLoadingShow(true)
        let objectResource = await loadInformationByAssignment();
        console.log(JSON.stringify(objectResource));
        service.put(
            getHostByAppName(DOCUMENT_NAME),
            `document/${context}/`,
            callbackRes,
            callbackError,
            objectResource
        );

    };

    return (
        <div>
            <AdaButton style={{height: 32, width:120}} className="c-btn ma-5 no-border c-outline-primary" onClick={() => handlerClickVisualize()}>
                <i style={{fontSize: 12, fontWeight:"bold"}}> Télécharger</i>
            </AdaButton>

        </div>
    );
}
