import React, {useEffect, useState} from "react";
import {columnsCra} from "./data/columns";
import {
    AdaFilterSubHeader,
    AdaTable,
    AdaActions,
    AdaButton, getEmployeeConnectedLight
} from "@adaming/ada-react-component";
import IntlMessages from "../../helpers/intlMessages";

import {useHistory, withRouter} from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import * as service from "../../service/crud.service";
import {CRA_NAME, getHostByAppName} from "../../service/host";
import AdvancedSearch from "./search/advanced.search";
import DetailsTimeSheet from "./details/details.timesheet";
import {GetUserFromSession} from "../../service/session.service";


const context = "timesheets";
const Timesheets = props => {
    const [cras, setCras] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [employee, setEmployee] = useState(GetUserFromSession)

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);

    const [currentFilters, setCurrentFilters] = useState({});


    let history = useHistory();

    


    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`action.common.delete`}/>,
                href: "",
                actionHandler: handlerOpenModalDelete,
                disabled: true
            },
            {
                actionName: <IntlMessages id={`action.common.edit`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            }
        ];
    };

    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };

    const callbackRes = res => {
        setCras(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
    };

    const initList = () =>{
        console.log("employee",employee)
        service.get(
            getHostByAppName(CRA_NAME),
            `${context}/byCollab/${employee.id}`,
            callbackRes,
            error => console.log(error)
        );
    }

    /*Initialization CRA List */
    React.useEffect(
        () => {
            if (!modalSuccessShow) {
                initList();
            }
        },
        [modalSuccessShow,employee]
    );
    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Delete Action*/
                actionsValue[0] = {...actionsValue[0], disabled: false};
                /*Activate : Activate Action*/
                //actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable Action*/
                //actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    /*Activate : Edit Action*/
                    actionsValue[1] = {
                        ...actionsValue[1],
                        href: `/${context}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);
                console.log("actions list : ", actionsValue);
                setAdvancedSearchShow(false);
            }
        },
        [selectedRows.length]
    );

    React.useEffect(()=>{     
        handlerAdvancedSearch(currentFilters, currentPaginationPerRow, currentPage);
    },[currentPaginationPerRow,currentPage, currentFilters])

    const handlerActivateAction = () => {
        console.log("in Activate action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), true);
    };

    const handlerDisableAction = () => {
        console.log("in Disable action", selectedRows);
        setLoadingModal(true);
        handlerEnableDisable(selectedRows.map(element => element.id), false);
    };

    const callbackResEnableDisable = () => {
        setLoadingModal(false);
        setModalSuccessShow(true);
    };

    const handlerEnableDisable = (ids, status) => {
        console.log("in handlerEnableDisable action", ids, status);
        service.put(
            getHostByAppName(CRA_NAME),
            `${context}/enable_disable_invoices`,
            callbackResEnableDisable,
            error => {
                console.log(error);
            },
            {
                status: status,
                ids: ids
            }
        );
    };

    const callBackResDelete = () => {
        setModalShow(false);
        setModalSuccessShow(true);
    }

    const setNewFilters =  (data) => {
        setCurrentFilters(data)
    }

    const handlerDeleteAction = () => {
        console.log("in Delete action", selectedRows);
        service.deletes(getHostByAppName(CRA_NAME), context, callBackResDelete, ((error)=>{console.log(error)}), selectedRows.map(obj => obj.id))
    };

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }

        if(page == null){
            page = defaultPage;
        }
        service.search(
            getHostByAppName(CRA_NAME),
            `${context}/advanced_search/get?size=${size}&page=${page}`,
            callbackRes,
            (error) => {
                console.log(error);
            },
            {...data, employee: employee.id}
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        console.log("handlerCancelAdvancedSearch");
        initList();
    }, []);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                    showActions={{exportExcel:false, add:true}}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsCra}
            items={cras}
            setFiltredItems={setFiltered}
        />
    );

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">

                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && <AdvancedSearch handlerAdvancedSearch={setNewFilters} employee={employee.id}
                                                                       handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}/>}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsCra}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <br/>
                            {selectedRows.length === 1 &&
                                <DetailsTimeSheet timeSheetId={selectedRows.length === 1 ? selectedRows[0].id : null}/>
                            }
                            <br/>
                            <SweetAlert
                                onClick={props.processAction}
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Confirmation"
                                onConfirm={() => handlerDeleteAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(Timesheets);
