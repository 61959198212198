import React, {Children} from "react";

const eventStyleGetter = (event) => {
    if(event.hd !== undefined && event.hd === true){
        var style = {
        'padding' : '2px 10px',
        'min-height': '40px !important',
        'background-color': '#b71c1c',
        'color': 'white',
        'border': '1px solid #DAE5F0',
        'border-radius': '6px',
        'border-right': `6px solid #b71c1c`,
        'box-shadow': '0 0.46875rem 2.1875rem rgba(0,0,0,0.03), 0 0.9375rem 1.40625rem rgba(0,0,0,0.03), 0 0.25rem 0.53125rem rgba(0,0,0,0.05), 0 0.125rem 0.1875rem rgba(0,0,0,0.03)',
        '.rbc-event-content' : {
            'font-size': '14px',
            'font-family': 'montserrat-semi-bold',
        }
        };
        return {
            style: style
        };
    }

    if(event.JF !== undefined && event.JF === true){
        var style = {
            'padding' : '2px 10px',
            'min-height': '40px !important',
            'background-color': 'rgb(168, 173, 188)',
            'color': 'white',
            'border': '1px solid #DAE5F0',
            'border-radius': '6px',
            'border-right': `6px solid #b71c1c`,
            'box-shadow': '0 0.46875rem 2.1875rem rgba(0,0,0,0.03), 0 0.9375rem 1.40625rem rgba(0,0,0,0.03), 0 0.25rem 0.53125rem rgba(0,0,0,0.05), 0 0.125rem 0.1875rem rgba(0,0,0,0.03)',
            '.rbc-event-content' : {
                'font-size': '14px',
                'font-family': 'montserrat-semi-bold',
            }
        };
        return {
            style: style
        };
    }

};
export default eventStyleGetter;
