import IntlMessages from "../../../helpers/intlMessages";
import React from "react";

export const columnsCra = [

    {
        name: <IntlMessages
            id="client.designation"
        />,
        selector: "name",
        width : "25%",
        sortable: false,
        filtrable: true,
    },
    {
        name: "Validation Collaborateur",
        selector: "client.name",
        sortable: false,
        filtrable: true,
        format: row => (row.employeeValidation  ? "Oui" : "Non")
    },
    {
        name: "Validation RH",
        selector: "client.name",
        sortable: false,
        filtrable: true,
        format: row => (row.rhValidation  ? "Oui" : "Non")
    },
    {
        name: "Statut",
        selector: "client.name",
        sortable: false,
        filtrable: true,
        format: row => (row.status !== null && row.status.length > 0 ? getValue(row.status) : "")
    },


];

const getValue =(list)=>{
    let value ="";
    list.forEach(item =>{
        if(item.finished)
            value = item.label
    })
    return value;
}