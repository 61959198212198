import Cras from "../views/timesheet/timesheets";
import AllTimesheets from "../views/timesheet/allTimesheets";
import EmployeesNoCra from "../views/timesheet/employees.no.cra";
import CraForm from "../views/timesheet/create/new.timesheet";
import CraUpdateForm from "../views/timesheet/update/update.timesheet";
import CraValidateForm from "../views/timesheet/update/validate.timesheet";
import Maintenance from "../views/pages/error/maintenance";
import ExportForm from "../views/timesheet/export/export.silae";

const timesheetsRoutes = [
   {path: "/timesheets", component: Cras},
   {path: "/allTimesheets", component: AllTimesheets},
   {path: "/employeesNoCra", component: EmployeesNoCra},
   {path: "/timesheets/new", component: CraForm},
   {path: "/allTimesheets/export", component: ExportForm},
   {path: "/timesheets/:timesheetsId/edit", component: CraUpdateForm},
   {path: "/allTimesheets/:timesheetsId/validate", component: CraValidateForm},
   { path: "/maintenance", component: Maintenance}

];

export default timesheetsRoutes;