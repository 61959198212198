import React, {Children} from "react";

const CustomCellWrapper = ({children, value}) =>
    React.cloneElement(Children.only(children), {
        style: {
            ...children.style,
            backgroundColor: (value.getDay() === 6 || value.getDay() === 0) ? '#f1f2f5' : 'white',
        },
    });

export default CustomCellWrapper;