import React, {useEffect, useState} from "react";
import {columnsCra} from "./data/columns";
import {
    AdaFilterSubHeader,
    AdaTable,
    AdaActions,
    AdaButton, getEmployeeConnectedLight, AdaInputText
} from "@adaming/ada-react-component";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IntlMessages from "../../helpers/intlMessages";

import {useHistory, withRouter} from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import * as service from "../../service/crud.service";
import {CRA_NAME, getHostByAppName} from "../../service/host";
import AdvancedSearch from "./search/advanced.search";
import DetailsTimeSheet from "./details/details.timesheet";
import {GetUserFromSession} from "../../service/session.service";
import {columnsEmployee} from "./data/columns.employee";
import Modal from "react-bootstrap/Modal";
import {FormGroup, Row, Col} from "reactstrap";

import text from './text.default.js'
import Spinner from "react-bootstrap/Spinner";
import {getLabelMonth} from "../commun/commun.function";

const context = "timesheets";
const EmployeesNoCra = props => {
    const [employees, setEmployees] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(true);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [employee, setEmployee] = useState(GetUserFromSession);
    const [objectSearch, setObjectSearch] = useState({})
    const [send, setSend] = useState(false)
    const [modalLoadingShow, setModalLoadingShow] = useState(false)
    const [mailText, setMailText] = useState(text())
    const [subjectMail, setSubjectMail] = useState("")

    let history = useHistory();


    const initActionsList = () => {
        return [
            {
                actionName: <IntlMessages id={`employee.nofify`}/>,
                href: "",
                actionHandler: sendModal,
                disabled: true
            }
        ];
    };

    const handlerOpenModalDelete = () => {
        setModalShow(true);
    };

    const callbackRes = res => {
        setEmployees(res);
        console.log("liste des employees : ", res);
        setFiltered(res);
    };

    /*Initialization CRA List */
    React.useEffect(
        () => {
            if (!modalSuccessShow) {
                //initList();
            }
        },
        [modalSuccessShow, employee]
    );

    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Delete Action*/
                actionsValue[0] = {...actionsValue[0], disabled: false};

                setActionsList(actionsValue);
                console.log("actions list : ", actionsValue);
            }
        },
        [selectedRows.length]
    );

    const sendModal = () => {
        setSend(true)
    }

    const onChangeMailCorpHandler = (text) => {
        console.log("mail text", text)
        setMailText(text);
    }
    const handlerNotifyAction = () => {

        let subject = subjectMail ? subjectMail : "RAPPEL CRA ".concat(getLabelMonth(objectSearch.month-1)).concat(" ").concat(objectSearch.year);
        console.log("in Activate action", selectedRows, subject, mailText);
        setModalLoadingShow(true);
        service.put(
            getHostByAppName(CRA_NAME),
            `${context}/employee/noCra/notify`,
            callBackResNotify,
            error => {
                console.log(error);
            },
            {...objectSearch, employeeDtos: selectedRows, subjectMail: subject, mailText: mailText}
        );
    };


    const callBackResNotify = () => {
        setModalLoadingShow(false)
        setSend(false);
        setModalSuccessShow(true);
    }


    const handlerAdvancedSearch = React.useCallback(data => {
        console.log("handlerAdvancedSearch", data, employee);
        setObjectSearch(data);
        service.search(
            getHostByAppName(CRA_NAME),
            `${context}/employee/noCra`,
            callbackRes,
            (error) => {
                console.log(error);
            },
            data
        );

    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        console.log("handlerCancelAdvancedSearch");
        setEmployees([])
        setFiltered([])
        setObjectSearch({})
    }, []);

    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                    showActions={{exportExcel: false, add: false}}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsEmployee}
            items={employees}
            setFiltredItems={setFiltered}
        />
    );

    function getDefaultSubject() {
        let subject = "RAPPEL CRA ".concat(getLabelMonth(objectSearch.month-1)).concat(" ").concat(objectSearch.year);
        return subject;
    }

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15 full-height">

                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && <AdvancedSearch handlerAdvancedSearch={handlerAdvancedSearch}
                                                                       handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}/>}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsEmployee}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                            />
                            <br/>

                            <br/>


                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>

                            <Modal
                                show={send}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop={"static"}
                            >
                                <Modal.Header>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <h4 className="mb-0 fs-22 bold-text" style={{color: "#FF0B0E"}}>Envoyer un
                                            Rappel </h4>
                                    </Modal.Title>
                                </Modal.Header>
                                <div>
                                    <Row className="col-sm-12 pt-3">
                                        <Col className="col-sm-12">
                                            <FormGroup>
                                                <IntlMessages id="invoice.object"/>
                                                <AdaInputText
                                                    name="subjectMail"
                                                    defaultValue={getDefaultSubject()}
                                                    onBlur={e => setSubjectMail(e.target.value)} style={{height: 38}}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="col-sm-12 pt-1">
                                        <Col className="col-sm-12">
                                            <br/>
                                            <ReactQuill style={{height: 400, maxHeight: 400}} theme="snow"
                                                        value={mailText} onChange={onChangeMailCorpHandler}/>
                                        </Col>
                                    </Row>
                                </div>

                                    <div style={{marginTop: 85, marginRight: 15}}>
                                        <Row className="pt-3">
                                            <Col className="col-sm-8">
                                                <div className="display-6 c-text-danger">

                                                </div>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-danger w-100"
                                                               onClick={() => setSend(false)}>
                                                        <IntlMessages id="action.common.cancel"/>
                                                    </AdaButton>

                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <AdaButton className="c-btn c-warning w-100"
                                                               onClick={() => handlerNotifyAction()}>
                                                        <IntlMessages id="action.common.submit"/>
                                                    </AdaButton>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                            </Modal>

                            <Modal
                                show={modalLoadingShow}
                                size="sm"
                                centered
                                backdrop={"static"}
                            >
                                <div className="align-self-center ">
                                    <Row className="pt-3">
                                        <Col>
                                            <Spinner animation="border" color="warning" role="status"
                                                     style={{width: '5rem', height: '5rem'}}/>
                                        </Col>
                                    </Row>
                                    <Row className="pt-3 pb-2"><Col>
                                        <div className="display-5 c-text-dark" style={{fontWeight:"bold"}}>
                                            Loading ...
                                        </div>
                                    </Col></Row>
                                </div>
                            </Modal>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default withRouter(EmployeesNoCra);
