import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {
    Calendar as BigCalendar,
    // DateLocalizer,
    momentLocalizer,
    // globalizeLocalizer,
    // move,
    Views
    // Navigate,
    // components,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import CalendarHeader from "../../components/calender/CalendarHeader";
import CalenderWrapper from "../../components/calender/calender.style";
import dummyEvents from "../../components/calender/events";
import EventDialog from "./../../components/calender/EventDialog";
import "moment/locale/fr";
import CustomCellWrapper from "../../components/calender/custom.view.slot";
import eventStyleGetter from "../../components/calender/custom.view.event";
import IntlMessages from "../../helpers/intlMessages";
import SweetAlert from "react-bootstrap-sweetalert";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);
let allViews = Object.keys(Views).map(k => Views[k]);

const Calender = props => {
    const [modal, setModal] = useState(false);
    const [modalErrorShow, setModalErrorShow] = useState(false);
    const [modalErrorEditShow, setModalErrorEditShow] = useState(false);
    const [action, setAction] = useState("add");
    const [modalEvent, setmodalEvent] = useState(null);
    const [events, setEvents] = useState([]);
    //const [events, setEvents] = useState([]);

    useEffect(() => {
        setEvents(props.events);
    }, [props.events]);


    useEffect(
        () => {
            if (!modal) {
                setmodalEvent(null);
            }
        },
        [modal]
    );

    useEffect(
        () => {
            if (modalEvent) {
                setModal(true);
            }
        },
        [modalEvent]
    );

    const updateEvent = data => {
        const allEvents = [...events];
        let index = allEvents.findIndex(event => event.id === data.id);
        allEvents.splice(index, 1, data);
        setEvents([...allEvents]);
        props.setEvents([...allEvents]);
    };

    const moveEvent = ({event, start, end}) => {
        const data = {
            ...event,
            start,
            end
        };
        updateEvent(data);
    };

    const resizeEvent = ({event, start, end}) => {
        /*console.log("in resize event,", event);
        console.log("in resize event start,", start);
        console.log("in resize event end,", end);
        const data = {
            ...event,
            start,
            end
        };
        updateEvent(data);*/
    };

    // const handleNavigate = (e) => {
    //     console.log('handleNavigate', e)
    // }

    const eventSubmitHandler = (data, action) => {
        if (action === "add") {
            if(props.edit){
                props.handlerSaveItem(data)
            }else{
                props.setEvents(pre => [...pre, data]);
            }
        } else if (action === "edit") {
            if(props.edit){
                props.handlerEditItem(data);
            }else{
                updateEvent(data);
            }
        }
        setModal(false);
    };

    const eventDeleteHandler = data => {
        if(props.edit){
            if(!props.rhValidation && props.editableDate) {
                props.handlerDeleteItem(data);
            }else{
                setModalErrorEditShow(true)
            }
        }else {
            const allEvents = [...events];
            let index = allEvents.findIndex(event => event.id === data.id);
            allEvents.splice(index, 1);
            setEvents(allEvents);
            props.setEvents(allEvents);
        }
        setModal(false);
    };

    const checkValidationEvent = (props) => {
        return (!props.rhValidation && props.editableDate) || (props.rhValidation === undefined && props.editableDate === undefined);
    }

    const onSelectEvent = event => {
        if(!props.rh){
            if(checkValidationEvent(props)){
                if (event.start.getDay() !== 6 && event.start.getDay() !== 0 && !event.hd && !event.JF) {
                    setAction("edit");
                    setmodalEvent(event);
                }
            }else{
                setModalErrorEditShow(true)
            }
        }
    };

    const onSelectSlot = slotInfo => {
        if(!props.rh) {
            if(checkValidationEvent(props)){
                if (
                    slotInfo.start.getDay() !== 6 &&
                    slotInfo.start.getDay() !== 0 &&
                    props.defaultStartDate <= slotInfo.start &&
                    props.defaultEndDate >= slotInfo.end
                ) {
                    if (getUnitEventsBySlot(slotInfo.start) < 1) {
                        setModalErrorShow(false);
                        setAction("add");
                        setmodalEvent(slotInfo);
                    } else {
                        //show error message
                        setModalErrorShow(true);
                    }
                }
            }else{
                setModalErrorEditShow(true)
            }

        }
    };

    const getUnitEventsBySlot = date => {
        let unit = 0;
        events
            .filter(obj => obj.start.getFullYear() === date.getFullYear() && obj.start.getMonth() === date.getMonth() && obj.start.getDate() === date.getDate())
            .map(obj => unit = unit + obj.unit)
        return unit;

    }

    return (
        <CalenderWrapper {...props}>
            <div className="roe-card-style mt-15 mb-30 mlr-15">
                <div
                    className="roe-card-body pb-15 plr-0"
                    style={{backgroundColor: "white", borderRadius: "6px"}}
                >
                    <DragAndDropCalendar
                        className="flex flex-1 container"
                        selectable
                        shape={"04"}
                        culture={"fr"}
                        localizer={localizer}
                        events={events}
                        onEventDrop={moveEvent}
                        resizable
                        onEventResize={resizeEvent}
                        defaultView={Views.MONTH}
                        defaultDate={props.defaultStartDate}
                        startAccessor="start"
                        endAccessor="end"
                        views={allViews}
                        showMultiDayTimes
                        show
                        components={{
                            toolbar: CalendarHeader,
                            dateCellWrapper: CustomCellWrapper
                        }}
                        // onNavigate={handleNavigate}
                        onSelectEvent={event => onSelectEvent(event)}
                        onSelectSlot={slotInfo => onSelectSlot(slotInfo)}
                        eventPropGetter={eventStyleGetter}
                    />
                </div>
            </div>
            <SweetAlert
                show={modalErrorShow}
                onConfirm={() => setModalErrorShow(false)}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
                error
            >
                <IntlMessages id="cra.invalid.period"/>
            </SweetAlert>

            <SweetAlert
                show={modalErrorEditShow}
                onConfirm={() => setModalErrorEditShow(false)}
                confirmBtnCssClass="sweet-alert-confirm-button"
                cancelBtnCssClass="sweet-alert-cancle-button"
                error
            >
                <IntlMessages id="cra.invalid.edit"/>
            </SweetAlert>

            {modal && (
                <EventDialog
                    {...props}
                    events={events}
                    modal={modal}
                    edit={props.edit}
                    toggleModal={() => setModal(!modal)}
                    event={modalEvent}
                    action={action}
                    eventSubmitHandler={eventSubmitHandler}
                    eventDeleteHandler={eventDeleteHandler}
                />
            )}
        </CalenderWrapper>
    );
};

const mapStateToProps = state => {
    return {
        ...state.themeChanger,
        themeSetting: {
            toolbarDisplayValue: state.themeSetting.toolbarDisplayValue,
            footerDisplayValue: state.themeSetting.footerDisplayValue
        }
    };
};

export default connect(
    mapStateToProps,
    null
)(Calender);
