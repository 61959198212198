import React, {useEffect, useState} from "react";
import * as service from "../../../service/crud.service";
import {CRA_NAME, getHostByAppName} from "../../../service/host";
import UserInfoWrapper from "./userinfo.style";
import {analyse} from "../../../helpers/constants";


export default function DetailsTimeSheet({timeSheetId, showIcon, events}) {

    const [timeSheetDetails, setTimeSheetDetails] = useState([]);

    useEffect(()=>{
        if (timeSheetId) {
            service.get(
                getHostByAppName(CRA_NAME),
                `timesheetitems/analysis/timesheet/${timeSheetId}`,
                setTimeSheetDetails,
                error => console.log(error)
            );
        }
    },[timeSheetId,events])


    return (
        <UserInfoWrapper className="fill-height pt-3 ml-3 mr-3">
            <div className="card roe-shadow-1 fill-height">
                <div className="card-body">
                        <div className="row">
                        {timeSheetDetails.map((item)=>
                            <div className="col-md-2 ml-3">
                                <div className="d-flex flex-column justify-content-center border-bottom py-1 mt-1 mb-1">
                                    <div className="d-flex align-items-center ">
                                        <div className="dot-indicator bg-info mt-1 mr-2"></div>
                                        <h4 className="mb-0 text-dark fs-18 bold-text">{item.value}</h4>
                                    </div>
                                    <small className="text-muted ml-3">{item.name}</small>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>

        </UserInfoWrapper>
    )

}