export default  [
    {
        id    : 28,
        title : 'TR',
        allDay: false,
        start : new Date("2020-04-02 08:00"),
        end   : new Date("2020-04-02 18:00"),
        desc  : 'Big conference for important people'
    },
    {
        id    : 29,
        title : 'Congé payé',
        allDay: false,
        start : new Date("2020-04-15 08:00"),
        end   : new Date("2020-04-15 18:00"),
        desc  : 'Big conference for important people',
        hd : true
    },
    {
        id    : 29,
        title : 'Congé payé (matin)',
        allDay: false,
        start : new Date("2020-04-16 08:00"),
        end   : new Date("2020-04-16 12:00"),
        desc  : 'Big conference for important people',
        hd : true
    },
    {
        id    : 29,
        title : 'TR',
        allDay: false,
        start : new Date("2020-04-16 13:00"),
        end   : new Date("2020-04-16 18:00"),
        desc  : 'Big conference for important people',
        hd : false
    },
    {
        id    : 29,
        title : 'Jour férié',
        allDay: true,
        start : "2020-04-22 08:00",
        end   : "2020-04-22 18:00",
        desc  : 'Big conference for important people',
        JF : true,
    }
]