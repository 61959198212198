import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormGroup from "react-bootstrap/FormGroup";
import {Controller, useForm} from "react-hook-form";
import IntlMessages from "../../../helpers/intlMessages";
import * as service from "../../../service/crud.service";
import * as specificService from "../../../service/employee.service";
import {CRA_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName} from "../../../service/host";
import {AdaSelect, AdaButton, AdaInputText} from "@adaming/ada-react-component";
import {months} from "./months";
import {GetOrganizationIdFromSession} from "../../../service/session.service";

export default function AdvancedSearchRH({handlerAdvancedSearch, handlerCancelAdvancedSearch, showCollab}) {

    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [employeeList, setEmployeeList] = useState([]);
    const [object, setObject] = useState({
        employee: null,
        year: "",
        month: "",
        fromDate: null,
        toDate: null,
        clientGroup: null,
        client: null,
        entity: null,
        organization: GetOrganizationIdFromSession()
    });
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [clientGroupList, setClientGroupList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [entityList, setEntityList] = useState([]);


    useEffect(() => {
        if (showCollab)
            specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees/light?page=0&size=10000`, setEmployeeList)
        service.getAllObj(getHostByAppName(CRA_NAME), `timesheets/getYear`, setYearList)
        service.getAllObj(getHostByAppName(CRM_NAME), `clients/groups`, setClientGroupList)
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
        let listMonth = [];
        for (let i = 1; i < 13; i++) {
            listMonth.push({
                key: i,
                label: <IntlMessages id={`month.${i}`}/>,
                value: i
            })
        }
        console.log("list month", listMonth)
        setMonthList(listMonth);
    }, [])

    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("employee", selectedOption);
        if (selectedOption !== null) {
            setValue("employee", selectedOption.object.user);
            setObject({...object, employee: selectedOption.object.user})
        } else {
            setValue("employee", null);
            setObject({...object, employee: null})
        }
    };

    const onSubmit = (data) => {
        console.log("data search", data)
        console.log("object search", object)
        handlerAdvancedSearch(object);
    }

    const clear = () => {
        reset({employee: null, year: "", month: "", fromDate: null, toDate: null, client: null, entity: null, organization: GetOrganizationIdFromSession()});
        setObject({organization: GetOrganizationIdFromSession()})
        handlerCancelAdvancedSearch();
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("year", selectedOption);
        if (selectedOption !== null) {
            setValue("year", selectedOption.key);
            setObject({...object, year: selectedOption.key})
        } else {
            setValue("year", null);
            setObject({...object, year: null})
        }
    };

    const handleChangeMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("month", selectedOption);
        if (selectedOption !== null) {
            setValue("month", selectedOption.key);
            setObject({...object, month: selectedOption.key})
        } else {
            setValue("month", null);
            setObject({...object, month: null})
        }
    };

    const handleChangeClient = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("client", selectedOption);
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setValue("entity", null);
            setObject({...object, client: selectedOption.key, entity: null})
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.key}`, setEntityList)
        } else {
            setValue("client", null);
            setValue("entity", null);
            setEntityList([])
            setObject({...object, client: null, entity: null})
        }
    };

    const handleChangeClientGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("clientGroup", selectedOption.key);
            setValue("client", null);
            console.log("clientGroup changed ",selectedOption.key )
            setObject({...object, clientGroup: selectedOption.key, group: null})
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/findByGroup/${selectedOption.key}`, setClientList)
        } else {
            setValue("clientGroup", null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
            setObject({...object, clientGroup: null, client: null})
        }
    };

    const handleChangeEntity = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        console.log("entity", selectedOption);
        if (selectedOption !== null) {
            setValue("entity", selectedOption.key);
            setObject({...object, entity: selectedOption.key})
        } else {
            setValue("entity", null);
            setObject({...object, entity: null})
        }
    };

    return (<div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>

                <Col className="col-sm-3">
                    <Row>
                        <Col>
                            <FormGroup>
                                {" "}
                                <div className="grey--text">
                                    <IntlMessages id="invoice.client"/>
                                </div>
                                <Controller
                                    as={<AdaSelect name={"client"}/>}
                                    name={"client"}
                                    isClearable
                                    errors={errors}
                                    options={clientList}
                                    onChange={handleChangeClient}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                {" "}
                                <div className="grey--text">
                                    <IntlMessages id="invoice.group.client"/>
                                </div>
                                <Controller
                                    as={<AdaSelect name={"clientGroup"}/>}
                                    name={"clientGroup"}
                                    isClearable
                                    errors={errors}
                                    options={clientGroupList}
                                    onChange={handleChangeClientGroup}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                {" "}
                                <div className="grey--text">
                                    <IntlMessages id="invoice.entity"/>
                                </div>
                                <Controller
                                    as={<AdaSelect name={"entity"}/>}
                                    name={"entity"}
                                    isClearable
                                    errors={errors}
                                    options={entityList}
                                    onChange={handleChangeEntity}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>


                <Col className="col-sm-3">
                    <Row>
                        <Col>
                            <FormGroup>
                                <IntlMessages id="month.label"/>
                                <Controller
                                    as={<AdaSelect name={"month"}/>}
                                    name={"month"}
                                    isClearable
                                    errors={errors}
                                    options={monthList}
                                    onChange={handleChangeMonth}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <IntlMessages id="common.year"/>
                                <Controller
                                    as={<AdaSelect name={"year"}/>}
                                    name={"year"}
                                    isClearable
                                    errors={errors}
                                    options={yearList}
                                    onChange={handleChangeYear}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>

                    </Row>
                </Col>

                <Col className="col-sm-3">
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="grey--text">
                                    <IntlMessages id="invoice.from.period"/>
                                </div>
                                <AdaInputText
                                    type="date" style={{height: 38}}
                                    name="fromDate"
                                    errors={errors}
                                    innerRef={register}
                                    onBlur={e => setObject({...object, fromDate: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <div className="grey--text">
                                    <IntlMessages id="invoice.end.period"/>
                                </div>
                                <AdaInputText
                                    type="date" style={{height: 38}}
                                    name="toDate"
                                    errors={errors}
                                    innerRef={register}
                                    onBlur={e => setObject({...object, toDate: e.target.value})}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                </Col>


                <Col className="col-sm-3">
                    <Row>
                        <Col>
                            <FormGroup>
                                <IntlMessages id="action.employee"/>
                                <Controller
                                    as={<AdaSelect name={"employee"}/>}
                                    name={"employee"}
                                    isClearable
                                    errors={errors}
                                    options={employeeList}
                                    onChange={handleChangeEmployee}
                                    control={control}
                                    innerRef={register}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="pt-4">
                        <Col >
                            <FormGroup>
                                <AdaButton style={{height: 35}} className="c-danger col-xl-5" onClick={() => clear()}>
                                    <IntlMessages id="action.common.cancel"/>
                                </AdaButton>
                                <AdaButton
                                    className="c-warning col-xl-6"
                                    type="submit"
                                    style={{marginLeft: 10, height: 35}}
                                >
                                    <IntlMessages id="common.search"/>
                                </AdaButton>
                            </FormGroup>
                        </Col>
                    </Row>

                </Col>


            </Row>

        </form>
    </div>)
}
